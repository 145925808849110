export const config = {
  header: {
    active: false,
    exception: ["/auth/login", "/auth/password-recovery", "/auth/change-password", "/auth/reset-password"]
  },
  sidebar: {
    active: true,
    exception: ["/auth/login", "/auth/password-recovery", "/auth/change-password", "/auth/reset-password"]
  },
  footer: {
    active: false,
    exception: ["/auth/login", "/auth/password-recovery", "/auth/change-password", "/auth/reset-password"]
  },
}
