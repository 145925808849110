import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { it_IT } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { AuthService } from './shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { FormattedResponse } from './shared/models/formatted-response';
import { InterceptorService } from './shared/helpers/interceptor.service';

import {
  CarOutline,
  CaretLeftOutline,
  DatabaseOutline,
  HomeOutline,
  InfoOutline,
  LockOutline,
  LogoutOutline,
  SettingOutline,
  StepBackwardOutline,
  UserOutline
} from '@ant-design/icons-angular/icons';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule } from 'ng-zorro-antd/icon';

const icons: IconDefinition[] = [StepBackwardOutline, CaretLeftOutline, SettingOutline, HomeOutline, InfoOutline, UserOutline, LockOutline, CarOutline, DatabaseOutline, UserOutline, LogoutOutline];

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    NzIconModule.forChild(icons)
  ],
  providers: [
    provideHttpClient(withFetch()),
    provideClientHydration(),
    { provide: NZ_I18N, useValue: it_IT },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: InterceptorService,
    //   multi: true,
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeAppFactory,
    //   deps: [AuthService, Router, ActivatedRoute],
    //   multi: true,
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

function initializeAppFactory(auth: AuthService, router: Router, aRoute: ActivatedRoute): () => Observable<any> {
  // Get logged user if exists
  return () => auth.getUser(true)
    .pipe(
      tap((response: FormattedResponse<any>) => {
        auth.currentUser.next(response?.data);
      }),
      catchError((error: any) => {
        if (aRoute.snapshot.url.toString().includes('auth/login')) {
        } else if (error.status === 401) {
          auth.currentUser.next(null);
          router.navigate(['/auth/login']);
        }
        return of(error);
      }),
    );
}
